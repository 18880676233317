<template>
  <div
    :class="['container-xl px-0 px-sm-3',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaPaquetes }]"
  >
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col-12 col-xl user-select-none pr-md-0">Paquetes</h2>
      <div class="col-12 col-xl-auto px-md-0">
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-xl-0 ml-md-0 mr-md-1 px-3"
          @click="paqueteActualizar('filtro')"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          v-if="esPermitido"
          flat
          dark-waves
          icon="camera"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-xl-0 mx-md-1 px-3 bg-white"
          @click="mostrarModalEscanear=!mostrarModalEscanear"
        >
          Escanear
        </mdb-btn>
        <mdb-btn
          flat
          dark-waves
          icon="file-invoice"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-xl-0 mx-md-1 px-3 bg-white"
          @click="listarOrdenesReempaque=true;mostrarOrdenesReempaque=!mostrarOrdenesReempaque"
        >
          Órdenes de reempaque
        </mdb-btn>
        <!-- <mdb-btn
          flat
          dark-waves
          icon="search"
          @click="mostrarLog=!mostrarLog"
          class="btn-bordeado my-xl-0 mx-md-1 px-3 bg-white"
        >
          {{mostrarLog ?  'Ocultar log' : 'Mostrar log' }}
        </mdb-btn> -->
        <mdb-btn
          v-if="esPermitido"
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-xl-0 ml-md-1 mr-md-0 px-3"
          @click="mostrarFormularioPaquete = true;
            modalTitulo = 'Datos del nuevo paquete';"
        >
          Nuevo paquete
        </mdb-btn>
        <!-- <div v-if="mostrarLog" class="text-left">
          <p class="mt-2 text-muted text-center">Detalles de las consulta:</p>
          <code style="color: gray;">
            {{logObtenidos.length && logObtenidos}}
          </code>
        </div> -->
      </div>
    </div>
    <div class="mb-page">
      <!-- Filtros -->
      <div class="row align-items-center justify-content-center justify-content-sm-between my-2 mx-0">
        <!-- Se muestra solo si se listan todos los paquetes o filtran por EsperaMiami -->
        <div
          v-if="(buscarPor.estatus === '' || buscarPorEstatusEsperaMiami) && esPermitido"
          :class="['col-auto botones-opcionales',
            { mostrar: paquetesIdFormatted && paquetesIdFormatted.length },
            {'opcionRoleAdmin': rol.includes('Admin')}]"
        >
          <div
            :class="['d-inline-block bg-white',
              {'cursor-not-allowed': (botonDeshabilitado || !paquetesTipoIndividual)
                || !paquetesMismoCliente}
            ]"
            title="Solo se permite en paquetes de tipo individual del mismo cliente"
          >
            <mdb-btn
              color="terciario"
              icon="box-open"
              :disabled="(botonDeshabilitado || !paquetesTipoIndividual) || !paquetesMismoCliente"
              title="Solo se permite en paquetes de tipo individual del mismo cliente"
              class="m-0 px-3"
              @click="paqueteReempacar"
            >
              Reempacar
            </mdb-btn>
          </div>
          <mdb-btn
            color="terciario"
            icon="dolly"
            :disabled="botonDeshabilitado"
            class="px-3 text-nowrap"
            @click="validarAsignarConsolidado"
          >
            Asignar a contenedor
          </mdb-btn>
          <!-- Accion permitida solo si el rol del usuario es Administrador -->
           <mdb-btn
            color="terciario"
            icon="qrcode"
            :disabled="botonDeshabilitado"
            class="px-3 text-nowrap"
            @click="validarImprimirEtiqueta"
          >
            Imprimir {{paquetesId.length > 1
              ? 'etiquetas'
              : 'etiqueta'
              }}
          </mdb-btn>
          <!-- Acción permitida solo si el rol del usuario es Administrador -->
          <div
            v-if="rol.includes('Admin')"
            :class="['d-inline-block bg-white',
              {'cursor-not-allowed': botonDeshabilitado || paquetesId.length != 1}
            ]"
            title="Solo se permite en 1 paquete a la vez"
          >
            <mdb-btn
              color="peligroso"
              icon="trash"
              :disabled="botonDeshabilitado || paquetesId.length != 1"
              class="m-0 px-3 text-nowrap"
              @click="metodoAlConfirmar='eliminarPaquete';mostrarModalConfirmarPermiso = true;"
            >
              Eliminar
            </mdb-btn>
          </div>
        </div>
        <div class="col-auto px-2">
          <mdb-btn
            flat
            dark-waves
            icon="list"
            :disabled="botonDeshabilitado"
            class="btn-bordeado py-2 px-3"
            @click="limpiarCliente = true;
              limpiarPaquete = true;
              buscarPor = {
                entregado: null,
                pagado: '',
                estatus: 'EsperaMiami',
                id: '',
                tipo: '',
                cliente: {
                  id: '',
                },
                fecha: {
                  desde: diaHaceTresMeses(),
                  hasta: diaActual(),
                },
              };
              paqueteActualizar('filtro');">
            Todos
          </mdb-btn>
        </div>
        <div class="col-12 col-sm">
          <PaqueteBuscador
            id="paqueteBuscador-form"
            class="my-2"
            placeholder="Ingrese el tracking o paquete ID"
            :limpiarPaquete="limpiarPaquete"
            @paquete="obtenerPaqueteBuscador($event)"
            @limpiar="limpiarPaquete = $event"
          />
          <label
            for="paqueteBuscador-form"
            class="outline-datalist-label top-8"
          >
            Buscar por tracking
          </label>
        </div>
        <div class="col-12 col-xl">
          <ClienteBuscador
            id="clienteBuscador-1"
            class="my-2"
            placeholder="Ingrese nombre o casillero del cliente"
            :limpiarCliente="limpiarCliente"
            @cliente="obtenerClienteBuscador($event)"
            @limpiar="limpiarCliente = $event"
          />
          <label
            for="clienteBuscador-form"
            class="outline-datalist-label top-8"
          >
            Buscar por cliente
          </label>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-sm-6 col-xl-3">
          <mdb-input
            v-model="buscarPor.fecha.desde"
            type="date"
            label="Desde"
            class="my-2 outline-date"
            outline
            @change="paqueteActualizar('filtro')"
          />
        </div>
        <div class="col-12 col-sm-6 col-xl-3">
          <mdb-input
            v-model="buscarPor.fecha.hasta"
            type="date"
            label="Hasta"
            class="my-2 outline-date"
            outline
            @change="paqueteActualizar('filtro')"
          />
        </div>
        <div class="col-12 col-sm-6 col-xl-3">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="tipo-select"
              v-model="buscarPor.tipo"
              class="custom-select"
              @change="paqueteActualizar('tipo')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value) in PaqueteTipos"
                :key="value"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="tipo-select"
              :class="buscarPor.tipo ? 'label-active' : 'label-inactive'"
            >
              Tipo
            </label>
          </div>
        </div>

        <div class="col-12 col-sm col-xl-3">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="pagado-select"
              v-model="buscarPor.pagado"
              class="custom-select"
              @change="paqueteActualizar('filtro')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                :value="true"
              >
                Si
              </option>
              <option
                :value="false"
              >
                No
              </option>
            </select>
            <label
              for="pagado-select"
              :class="buscarPor.pagado ? 'label-active' : 'label-inactive'"
            >
              Pagado
            </label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xl">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select"
              v-model="buscarPor.estatus"
              class="custom-select"
              @change="paqueteActualizar('estatus')"
            >
              <option value="">Mostrar todos</option>
              <option
                v-for="(value, key) in PaqueteEstatus"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select"
              :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>

        <div class="col-auto col-md-6 col-xl-auto">
          <div class="custom-control custom-switch py-2">
            <!-- Filtro leve -->
            <input
              id="check-entregado"
              v-model="buscarPor.entregado"
              type="checkbox"
              name="checkbox-entregado"
              class="custom-control-input"
              @change="paqueteActualizar('filtro')"
            >
            <label class="custom-control-label" for="check-entregado">
              Mostrar entregados y devueltos
            </label>
          </div>
        </div>

        <div
          v-if="buscarPorEstatusEsperaMiami && paquetes.length"
          :class="['col-auto px-2 order-1', {'animated fadeIn': buscarPor.estatus.length}]"
        >
          <mdb-btn
            flat
            dark-waves
            class="btn-bordeado py-2 px-3"
            icon="check-square"
            :icon-class="[{'texto-terciario': paquetesId.length}]"
            @click="seleccionarTodo"
          >
            {{ textoBotonSeleccionar }}
          </mdb-btn>
        </div>

      </div>
      <!-- Tabla -->
      <ul data-table="sticky" class="table">
        <li class="encabezado row align-items-end">
          <!-- Columna: Fecha -->
          <div
            class="col col-md-2 pl-4 px-md-0 filtrable"
            @click="ordenarColumna('fecha')"
          >
            <span class="d-md-none">
              Detalles
            </span>
            <span class="d-none d-md-inline">
              Fecha recibido
            </span>
            <div
              v-if="orderBy.fecha != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.fecha}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.fecha}"
              />
            </div>
          </div>
          <!-- Columna: Detalles -->
          <div class="col pl-md-0 d-none d-md-block">
            Detalles
          </div>
          <!-- Columna: Cliente -->
          <div class="col-3 d-none d-md-block">Cliente</div>
          <!-- Columna: Estatus -->
          <div
            class="col d-none d-sm-block filtrable"
            @click="ordenarColumna('estatus')"
          >
            Estatus
            <div
              v-if="orderBy.estatus != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon icon="sort-up" :class="{'activo': orderBy.estatus}" />
              <font-awesome-icon icon="sort-down" :class="{'activo': !orderBy.estatus}" />
            </div>
          </div>
        </li>
        <li v-if="paquetes && !paquetes.length" class="no-items">
          No hay paquetes
        </li>
        <li v-if="loadingPaquetes">
          <CargandoVista clase-adicional="vc-50px" />
        </li>
        <li
          v-else
          v-for="(paquete, i) in paquetes"
          :key="`paquete-${i}`"
          class="contenido row align-items-center"
          @click="$router.push({ name: 'Paquete', params: { paqueteId: paquete.id } })"
        >
          <div class="col col-sm-2 d-none d-md-block">
            <label
              class="opciones-checkbox py-3"
              title="Seleccionar paquete"
              @click.stop
            >
              <input
                v-model="paquetesId"
                type="checkbox"
                name="selecionar-paquete-checkbox"
                :value="paquete"
              />
              <span class="icono-checkbox" />
              {{ paquete.fecha.formatted }}
            </label>
          </div>
          <div class="col px-sm-0">
            <!-- Fecha -->
            <label
              class="opciones-checkbox py-2 d-block d-md-none"
              title="Seleccionar paquete"
              @click.stop
            >
              <input
                v-model="paquetesId"
                type="checkbox"
                name="selecionar-paquete-checkbox"
                :value="paquete"
              />
              <span class="icono-checkbox" />
              {{ paquete.fecha.formatted }}
            </label>
            <!-- Detalles -->
            Paquete ID:
            <span :class="paquete.numeroHR ? 'texto-terciario' : 'text-muted user-select-none'">
              {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
            </span>
            <p class="mb-0">
              Tracking:
              <span :class="paquete.tracking ? '' : 'text-muted user-select-none'">
                {{ paquete.tracking ? paquete.tracking : "Sin especificar" }}
              </span>
            </p>
            <p class="mb-0">
              <span title="Peso por volumen">
                vlbs: {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                }}
                |
              </span>
              <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span>
              <span title="Pies cúbicos">
                ft<sup>3</sup>: {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                }}
              </span>
            </p>
            Tipo: {{ paquete.tipo }}
            <p
              v-if="paquete.observacion && paquete.observacion.length"
              class="mb-0 texto-line-clamp"
            >
              Observación:
              <span :class="paquete.observacion ? '' : 'text-muted user-select-none'">
                {{ paquete.observacion ? paquete.observacion.length >= 500 ? paquete.observacion.substring(0,100) + "..." : paquete.observacion : "Sin especificar" }}
              </span>
            </p>
            <!-- Cliente -->
            <div
              v-if="paquete.cliente && paquete.cliente.nombre"
              class="d-md-none pt-2"
            >
              Cliente:
              <span class="texto-terciario">
                {{ paquete.cliente.nombre }}
              </span>
              <p class="mb-0">
                Casillero: <span :class="paquete.cliente.casillero ? '' : 'text-muted user-select-none'">{{paquete.cliente.casillero ? paquete.cliente.casillero : 'Sin especificar'}}</span>
              </p>
              <div
                v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length
                  || paquete.cliente.emails && paquete.cliente.emails.length"
                class="row ml-n1 mr-0 my-1 mr-sm-3"
              >
                <template v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length && paquete.cliente.telefonos[0].numero">
                  <div class="col px-0">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`tel:+${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      icon="phone-alt"
                      icon-class="fa-2x"
                      size="sm"
                      class="btn-bordeado m-1 px-1 py-2"
                      title="Llamada"
                      @click.native.stop
                    />
                  </div>
                  <div class="col px-1">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`sms:+${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      icon="sms"
                      icon-class="fa-2x"
                      size="sm"
                      class="btn-bordeado m-1 px-1 py-2"
                      tile="SMS"
                      @click.native.stop
                    />
                  </div>
                  <div class="col px-0">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`https://wa.me/${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      fab
                      icon="whatsapp"
                      icon-class="fa-2x"
                      size="sm"
                      class="btn-bordeado m-1 px-1 py-2"
                      title="WhatsApp"
                      @click.native.stop
                    />
                  </div>
                </template>
                <div
                  v-if="paquete.cliente.emails && paquete.cliente.emails.length && paquete.cliente.emails[0].email"
                  class="col col-xl pl-1 pr-0"
                >
                  <mdb-btn
                    block flat dark-waves
                    tag="a"
                    :href="`mailto:${paquete.cliente.emails[0].email}`"
                    role="button"
                    icon="envelope"
                    icon-class="fa-2x"
                    size="sm"
                    class="btn-bordeado m-1 px-1 py-2"
                    title="E-mail"
                    @click.native.stop
                  />
                </div>
              </div>
            </div>
            <div
              v-else-if="!paquete.cliente && (esPermitido || esAgencia)"
              class="d-md-none pt-2"
            >
              Cliente:
              <span
                class="btn btn-flat ripple-parent btn-sm btn-bordeado m-1 px-3 d-md-none"
                @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                  objetoAlConfirmarPermiso = paquete.id;"
              >
                Sin asignar
              </span>
            </div>
            <!-- Estatus -->
            <div class="d-sm-none mt-2">
              <p
                v-if="paquete.cliente && paquete.cliente.nombre
                  && paquete.envio"
                class="mb-0"
              >
                Envío:
                <span :class="{'text-muted user-select-none': !paquete.envio}">
                  {{
                    paquete.envio
                      ? ConsolidadoTipoEnvio[paquete.envio] : "Sin especificar"
                  }}
                </span>
              </p>
              <p class="mb-0">
                Estatus:
                <span :class="{'text-muted user-select-none': !paquete.estatus}">
                  {{
                    paquete.estatus
                      ? PaqueteEstatus[paquete.estatus] : "Sin especificar"
                  }}
                </span>
                <span
                  v-if="paquete.estatus == 'EsperaMiami' && paquete.almacen"
                  :class="{'text-muted user-select-none': !paquete.estatus}"
                >
                  - {{ paquete.almacen.nombre }}
                </span>
              </p>
              <span
                v-if="paquete.cliente && paquete.cliente.nombre
                  && paquete.agenciaDestino"
                class="d-block"
              >
                Agencia destino: {{ paquete.agenciaDestino.nombre }}
              </span>
              <span class="d-block">
                Consolidado:
                <span v-if="paquete.consolidado">
                  {{ paquete.consolidado.master ? paquete.consolidado.master : 'Sin guía master'}}
                </span>
                <a
                  v-else-if="!paquete.consolidado && esPermitido"
                  class="text-primary ml-1"
                  @click.stop="
                    paquetesId = [paquete];
                    validarAsignarConsolidado()"
                >
                  Sin asignar
                </a>
                <span v-else>
                  Sin asignar
                </span>
              </span>
              <div
                v-if="!(paquete.cliente && paquete.cliente.nombre
                  && paquete.direccionDestino && paquete.direccionDestino.id)"
                class="mb-0"
              >
                <span class="mr-2 text-muted user-select-none">Sin dirección de envío</span>
                <mdb-btn
                  v-if="esPermitido || esAgencia"
                  flat
                  dark-waves
                  icon="plus"
                  size="sm"
                  class="btn-bordeado m-0 p-2"
                  @click.native.stop="mostrarModalSeleccionarDireccion = true;
                    paqueteSeleccionado = paquete;"
                >
                  Asignar
                </mdb-btn>
              </div>
            </div>
          </div>
          <div class="col-3 pr-xl-0 d-none d-md-block">
            <div v-if="paquete.cliente && paquete.cliente.nombre">
              {{ paquete.cliente.nombre }}
              <p class="mb-0">
                Casillero:
                <span :class="{'text-muted user-select-none': !paquete.cliente.casillero}">
                  {{paquete.cliente.casillero ? paquete.cliente.casillero : 'Sin especificar'}}
                </span>
              </p>
              <div
                v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length
                  || paquete.cliente.emails && paquete.cliente.emails.length"
                class="row ml-n1 mr-0 my-1 mr-sm-3"
              >
                <template v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length && paquete.cliente.telefonos[0].numero">
                  <div class="col col-xl-auto px-0">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`tel:+${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      icon="phone-alt"
                      icon-class="fa-lg"
                      size="sm"
                      class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                      title="Llamada"
                      @click.native.stop
                    />
                  </div>
                  <div class="col col-xl-auto px-1">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`sms:+${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      icon="sms"
                      icon-class="fa-lg"
                      size="sm"
                      class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                      title="SMS"
                      @click.native.stop
                    />
                  </div>
                  <div class="col col-xl-auto px-0">
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`https://wa.me/${paquete.cliente.telefonos[0].numero}`"
                      role="button"
                      fab
                      icon="whatsapp"
                      icon-class="fa-lg"
                      size="sm"
                      class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                      title="WhatsApp"
                      @click.native.stop
                    />
                  </div>
                </template>
                <div
                  v-if="paquete.cliente.emails && paquete.cliente.emails.length
                    && paquete.cliente.emails[0].email"
                  :class="['col col-xl-auto px-0',
                    {'pl-1': paquete.cliente.telefonos && paquete.cliente.telefonos.length}]"
                >
                  <mdb-btn
                    block flat dark-waves
                    tag="a"
                    :href="`mailto:${paquete.cliente.emails[0].email}`"
                    role="button"
                    icon="envelope"
                    icon-class="fa-lg"
                    size="sm"
                    class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                    title="E-mail"
                    @click.native.stop
                  />
                </div>
              </div>
            </div>
            <span
              v-else
              class="btn btn-flat ripple-parent btn-sm btn-bordeado m-1 px-3"
              @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                objetoAlConfirmarPermiso = paquete.id;"
            >
              Sin asignar
            </span>
          </div>
          <div class="col d-none d-sm-block">
            <p
              v-if="paquete.cliente && paquete.cliente.nombre
                && paquete.envio"
              class="mb-0"
            >
              Envío:
              <span :class="{'text-muted user-select-none': !paquete.envio}">
                {{
                  paquete.envio
                    ? ConsolidadoTipoEnvio[paquete.envio] : "Sin especificar"
                }}
              </span>
            </p>
            <p class="mb-0">
              Estatus:
              <span :class="{'text-muted user-select-none': !paquete.estatus}">
                {{
                  paquete.estatus
                    ? PaqueteEstatus[paquete.estatus] : "Sin especificar"
                }}
              </span>
              <span
                v-if="paquete.estatus == 'EsperaMiami' && paquete.almacen"
                :class="{'text-muted user-select-none': !paquete.estatus}"
              >
                - {{ paquete.almacen.nombre }}
              </span>
            </p>
            <span
              v-if="paquete.cliente && paquete.cliente.nombre
                && paquete.agenciaDestino"
              class="d-block"
            >
              Agencia destino: {{ paquete.agenciaDestino.nombre }}
            </span>
            <span class="d-block">
              Consolidado:
              <span v-if="paquete.consolidado">
                {{ paquete.consolidado.master ? paquete.consolidado.master : 'Sin guía master'}}
              </span>
              <a
                v-else
                :class="['ml-1', esAgencia ? 'text-muted user-select-none' : 'text-primary' ]"
                @click.stop="esPermitidoAsignarConsolidado(paquete)"
              >
                Sin asignar
              </a>
            </span>
            <div
              v-if="!(paquete.cliente && paquete.cliente.nombre
                && paquete.direccionDestino && paquete.direccionDestino.id)"
              class="mb-0"
            >
              <span class="mr-2 text-muted user-select-none">Sin dirección de envío</span>
              <mdb-btn
                v-if="(esPermitido || esAgencia) && paquete.estatus != 'Devuelto'"
                flat
                dark-waves
                icon="plus"
                size="sm"
                class="btn-bordeado m-0 p-2"
                @click.native.stop="mostrarModalSeleccionarDireccion = true;
                  paqueteSeleccionado = paquete;"
              >
                Asignar
              </mdb-btn>
            </div>
          </div>
        </li>
        <li v-if="loadingPaquetesPaginacion">
          <CargandoVista clase-adicional="vc-50px" />
        </li>
      </ul>
    </div>
    <!-- Modal Nuevo paquete  / reempaque-->
    <FormularioPaquete
      :titulo="modalTitulo"
      :esReempaque="reempaque"
      :paquetes="paquetesId"
      :paquetesId="paquetesIdFormatted"
      :mostrar-modal="mostrarFormularioPaquete"
      :variablesUpdateQueryViewPaquetes="variablesUpdateQueryViewPaquetes"
      :personaIdReempacar="paquetesId && paquetesId.length
        && paquetesId[0].cliente && reempaque
          ? paquetesId[0].cliente.id
          : ''"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="
        mostrarFormularioPaquete = $event;
        reempaque = false;
      "
      @paginacionResetear="paqueteActualizar('filtro')"
    />
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="metodoConfirmarPermiso"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <!-- Modal Asignar Cliente -->
    <mdb-modal
      centered
      elegant
      :show="mostrarModalAsignarCliente"
      @close="mostrarModalAsignarCliente = false"
    >
      <mdb-modal-header>
        <mdb-modal-title> Asignar cliente a un paquete </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Cliente</header>
        <ClienteBuscador class="my-2" @cliente="cliente = $event" />
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="paqueteAsignarCliente"
          >
            Asignar cliente
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal Ordenes de reempaque -->
    <ModalOrdenReempaque
      :mostrar-modal="mostrarOrdenesReempaque"
      :titulo="tituloModalOrdenesReempaque"
      :listarOrdenes="listarOrdenesReempaque"
      :variablesUpdateQueryViewPaquetes="variablesUpdateQueryViewPaquetes"
      @alertaMensaje="alertaMensaje=$event"
      @paginacionResetear="paqueteActualizar('filtro')"
      @close="mostrarOrdenesReempaque = $event;
        tituloModalOrdenesReempaque='Ordenes de reempaque';
        listarOrdenesReempaque=false"
    />
    <!-- Modal Seleccionar Dirección -->
    <ModalSeleccionarDireccion
      :mostrar-modal="mostrarModalSeleccionarDireccion"
      :paqueteSeleccionado="paqueteSeleccionado"
      :variablesUpdateQueryViewPaquetes="variablesUpdateQueryViewPaquetes"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalSeleccionarDireccion = $event;"
    />
    <!-- Modal Asignar Contenedor -->
    <ModalSeleccionarConsolidado
      :mostrar-modal="mostrarModalAsignarContenedor"
      :variablesUpdateQueryViewPaquetes="variablesUpdateQueryViewPaquetes"
      :paquetesId="paquetesIdFormatted"
      :paquetes="paquetesId"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarContenedor = $event;"
    /><!-- Modal Escaner -->
    <ModalEscaner
      :mostrar-modal="mostrarModalEscanear"
      @alertaMensaje="alertaMensaje=$event"
      @paquetes="paqueteReempacar($event)"
      @cerrar="mostrarModalEscanear = $event;"
    />
    <!-- Modal imprimir etiqueta -->
    <ModalEtiquetas
      :mostrar-modal="mostrarModalEtiqueta"
      :esPaquetes="true"
      :paquetes="paquetesId"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalEtiqueta = $event;"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminarPaquete"
      mensaje="Si remueves este paquete, se eliminará en su totalidad, esta acción es irreversible"
      @cerrar="mostrarModalConfirmarEliminarPaquete = $event;stepConfirmarClave=1;"
      @eliminar="metodoAlConfirmar='eliminarPaquete';
        mostrarModalConfirmarPermiso = true;mostrarModalConfirmarEliminarPaquete=false"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view :key="$route.fullPath"
        @actualizarReempaque="paqueteActualizar('filtro');"
      />
    </transition>
  </div>
</template>

<script>
import ModalOrdenReempaque from '@/components/FormularioOrdenReempaque.vue'
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import ClienteBuscador from "@/components/ClienteBuscador.vue"
import PaqueteBuscador from "@/components/PaqueteBuscador.vue"
import FormularioPaquete from "@/components/FormularioPaquete.vue";
import ModalEscaner from "@/components/ModalEscaner.vue";
import ModalSeleccionarDireccion from "@/components/ModalSeleccionarDireccion.vue";
import ModalSeleccionarConsolidado from "@/components/ModalSeleccionarConsolidado.vue";
import { PaqueteEstatus, PaqueteTipos } from "@/constantes/paquetes.js"
import ModalEtiquetas from "@/components/ModalEtiquetas.vue";
import { ConsolidadoTipoEnvio } from "@/constantes/consolidados.js"
import gql from "graphql-tag";
import paqueteEliminarGql from "@/graphql/paqueteEliminar.gql";
import paqueteAsignarClienteGql from "@/graphql/paqueteAsignarCliente.gql"
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import paquetesGql from "@/graphql/paquetes.gql";
import { leerRoles, leerAgencias } from "@/utils/datosToken.js";
import { mostrarSoloDosDecimales, obtenerMarcaTemporal, autoComaYAutoY } from "@/funciones/funciones.js";
import {
  mdbInput,
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: "Paquetes",
  components: {
    mdbInput,
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    FormularioPaquete,
    CargandoVista,
    ClienteBuscador,
    AlertaMensaje,
    ModalSeleccionarDireccion,
    ModalSeleccionarConsolidado,
    ModalConfirmarPermiso,
    ModalOrdenReempaque,
    ModalEscaner,
    ConfirmacionEliminar,
    PaqueteBuscador,
    ModalEtiquetas
  },
  data() {
    return {
      // Lodader en los filtros de paquete
      loadingPaquetes: false,
      // Lodader en la paginación de paquete
      loadingPaquetesPaginacion: false,
      // Imprimir etiqueta
      mostrarModalEtiqueta: false,
      // Escaner
      mostrarModalEscanear: false,
      // Limpiar campo paquete tracking o paquete ID
      limpiarPaquete: false,
      // Limpiar campo cliente
      limpiarCliente: false,
      // Eliminar paquetes
      mostrarModalConfirmarEliminarPaquete: false,
      rol: leerRoles(),
      agenciasId: leerAgencias(),
      stepConfirmarClave: 1,
      // Ordenes de reempaque
      mostrarOrdenesReempaque: false,
      // mostrarLog: false,
      logObtenidos: '',
      listarOrdenesReempaque: false,
      tituloModalOrdenesReempaque: '',
      // Funcionalidad
      mostrarSoloDosDecimales,
      // Paginación
      pagina: 1,
      mostrarMas: true,
      // Confirmar permiso
      metodoAlConfirmar: '',
      objetoAlConfirmarPermiso: {},
      mostrarModalConfirmarPermiso: false,
      // Seleccionar dirección
      mostrarModalSeleccionarDireccion: false,
      paqueteSeleccionado: {},
      // Otros
      alertaMensaje: {contenido: ''},
      buscarPor: {
        entregado: null,
        pagado: "",
        estatus: "EsperaMiami",
        id: "",
        tipo: "",
        cliente: {
          id: '',
        },
        fecha: {
          desde: this.diaHaceTresMeses(),
          hasta: this.diaActual(),
        },
      },
      orderBy: {
        fecha: false,
        cliente: null,
        estatus: null,
      },
      consolidadoTemp: {},
      botonDeshabilitado: false,
      deshabilitarCheckbox: true,
      PaqueteEstatus,
      PaqueteTipos,
      ConsolidadoTipoEnvio,
      paquetesId: [],
      fotosId: [],
      consolidadoId: "",
      personaId: "",
      mostrarFormularioPaquete: false,
      mostrarModalEditarEnvio: false,
      mostrarModalAsignarCliente: false,
      mostrarModalAsignarContenedor: false,
      cliente: {},
      modalTitulo: "",
      reempaque: false,
      paqueteSeleccionadoId: "",
      paquetes: [],
      orderByActual: [],
    };
  },
  mounted(){
    this.scroll();
  },
  computed: {
      variablesUpdateQueryViewPaquetes() {
        // variablesQuery
        const variables = { 
          filter: {
            reempacado: false,
            estatus: 'EsperaMiami',
            estatus_not_in: ['Entregado', 'Devuelto'],
            fecha_gte: {formatted: this.diaHaceTresMeses()},
            fecha_lte: {formatted: this.diaActual()}
          },
          orderBy: ['fecha_desc', 'numeroHR_desc'],
          offset: 0,
          // Logintud de página recomendada: '10'
          first: 20,
        }
        if(this.rol.includes('Agencia') && this.agenciasId.length){
          Object.assign(variables.filter, {
            agenciaDestino_in: this.agenciasId.map(agenciaId => ({id: agenciaId}))
          })
        }
        return variables
      },
    esPermitido(){
      return this.rol.includes('Admin') || this.rol.includes('OpAlmacen')
        ? true
        : false
    },
    esAgencia(){
      return this.rol.includes('Agencia')
        ? true
        : false
    },
    textoBotonSeleccionar(){
      const paquetesDisponibles = this.paquetes.length
      const paquetesSeleccionados = this.paquetesId.length
      return paquetesDisponibles === paquetesSeleccionados
        ? "Deseleccionar todo"
        : "Seleccionar todo";
    },
    ocultarOverflowEnVistaPaquetes() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
    buscarPorEstatusEsperaMiami() { // Evalua filtro de búsqueda por estatus === 'EsperaMiami'
      return (this.buscarPor.estatus === 'EsperaMiami')
    },
    paquetesEsperaMiami() {
      if (!this.paquetesId.length) return false;
      let paqueteEnMiami = true;
      this.paquetesId.forEach((paquete) => {
        if(paquete.estatus !== "EsperaMiami"){
          paqueteEnMiami = false
        }
      });
      if(paqueteEnMiami){
      // retornará verdadero si tobtenerClienteBuscadorodos los paquetes seleccionados tienen estatus "EsperaMiami"
        return true
      }
      return false;
    },
    paquetesTipoIndividual() {
      if (!this.paquetesId.length) return false;
      const tipos = [];
      this.paquetesId.forEach((paquete) => {
        tipos.push(paquete.tipo);
      });
      if (Object.values(tipos).includes("Reempaque")) return false;
      // retornará verdadero si todos los paquetes seleccionados son de tipo individual
      return true;
    },
    paquetesMismoCliente() {
      if (!this.paquetesId.length) return false;
      let sinCliente = false;
      const clientes = [];
      this.paquetesId.forEach((paquete) => {
        paquete.cliente && paquete.cliente.nombre
        ? clientes.push(paquete.cliente.nombre)
        : sinCliente = true;
      });
      const clientesSet = [...new Set(clientes)];
      // si la logitud es 0, no hay clientes seleccionados
      if(!clientesSet.length) return  false;
      // Si la longitud es 1, todos los clientes son los mismos
      if(sinCliente || clientesSet.length !== 1) return  false;
      // Retorna true si todos los clientes son los mismos
      return true;
    },
    paquetesIdFormatted() {
      if (!this.paquetesId) return false;
      const ids = [];
      this.paquetesId.forEach((paquete) => {
        ids.push(paquete.id);
      });
      // retornará todos los id de los paquetes seleccionados
      return ids;
    },
  },
  methods: {
    // Etiquetas
    validarImprimirEtiqueta(){
      if(this.paquetesId.length > 6){
        return this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida con un máximo de seis (6) paquetes',
          tipo: 'advertencia',
        }
      }
      const sin = {
        cliente: false,
        consolidado: false,
        envio: false,
      }
      const mensajesErrorSinFormato = [];
      let mensajeError = '';
      const masDeUnPaquete = this.paquetesId.length > 1
      function crearMensajeError(entidadFaltante){
        mensajesErrorSinFormato.push(entidadFaltante)
      }
      this.paquetesId.forEach((paquete) => {
        if(!(paquete.cliente && paquete.cliente.nombre)) sin.cliente = true
        if(!paquete.envio) sin.envio = true
        if(!(paquete.consolidado && paquete.consolidado.id)) sin.consolidado = true
      });
      if(sin.cliente){
        crearMensajeError('cliente');
      }
      if(sin.envio){
        crearMensajeError('tipo de envío');
      }
      if(sin.consolidado){
        crearMensajeError('consolidado');
      }
      mensajeError = autoComaYAutoY(mensajesErrorSinFormato)
      if(mensajeError.length){
        return this.alertaMensaje = {
          contenido: `${masDeUnPaquete ? 'Uno o más paquetes seleccionados' : 'El paquete seleccionado'}`+
            ` no ${masDeUnPaquete ? 'cuentan' : 'cuenta'} con: ${mensajeError} asignado. Por favor verifica tus datos`,
          tipo: 'advertencia',
        }
      }
      this.mostrarModalEtiqueta = true
    },
     esPermitidoAsignarConsolidado(paqueteAsignar){
      if(!this.esPermitido) return
      this.paquetesId = [paqueteAsignar];
      this.validarAsignarConsolidado() 
    },
    eliminarPaquete(){
      // Validación extra
      if(this.paquetesId.length != 1) return
      this.botonDeshabilitado = true;
      const id = this.paquetesIdFormatted[0]
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteEliminarGql}
          `,
          variables: {
            id,
          },
          update: (store, {data: {paqueteEliminar}}) => {
             const msg = paqueteEliminar.codigo;
            switch (msg) {
              case "Correcto":
                break;
              case "Fallido":
                this.alertaMensaje = {
                    contenido:  'Ha ocurrido un error eliminando el paquete.'
                    +' Por favor intenta de nuevo',
                    tipo: 'error',
                  };
                this.botonDeshabilitado = false;
                return;
              default:
                this.alertaMensaje = {
                  contenido:  'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  tipo: 'error',
                };
                this.botonDeshabilitado = false;
                return;
            }

            const data = store.readQuery({
              query: paquetesGql,
              variables: this.variablesUpdateQueryViewPaquetes
            });

            data.Paquete = data.Paquete.filter((p) => {
              return p.id !== id
            })

            store.writeQuery({
              query: paquetesGql,
              variables: this.variablesUpdateQueryViewPaquetes,
              data
            });
            this.paqueteActualizar('filtro');
            this.botonDeshabilitado = false;
            this.paquetesId = []
            this.alertaMensaje = {
              contenido: 'Se ha eliminado el paquete correctamente',
              tipo: 'correcto',
            };
          },
        })
        .catch(()=>{
          this.botonDeshabilitado = false
          return this.alertaMensaje = {
            contenido:  'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
            tipo: 'error',
          };
        })
    },
    obtenerClienteBuscador(cliente){
      if(cliente && cliente.id){
        this.buscarPor.cliente.id = cliente.id
      } else {
        this.buscarPor.cliente.id = ''
      }
      this.paqueteActualizar('filtro')
    },
    obtenerPaqueteBuscador(paquete){
      if(paquete && paquete.id){
        this.buscarPor.id = paquete.id  
      } else {
        this.buscarPor.id = ''
      }
      this.paqueteActualizar('filtro')
    },
    ordenarColumna(columna){
      if(columna == 'fecha'){
        this.orderBy.estatus = null;
        this.orderBy.cliente = null;
        this.orderBy.fecha === null
          ? this.orderBy.fecha = false  // se ordena descendente
          : this.orderBy.fecha
            ? this.orderBy.fecha = false
            : this.orderBy.fecha = true  // se ordena ascendente
        if(this.orderBy.fecha) {
          this.orderByActual = ['fecha_asc', 'numeroHR_desc']
          this.paqueteActualizar('filtro')
        } else {
          this.orderByActual = ['fecha_desc', 'numeroHR_desc']
          this.paqueteActualizar('filtro')
        }
      }
       if(columna == 'estatus'){
        this.orderBy.fecha = null;
        this.orderBy.cliente = null;
        this.orderBy.estatus === null
          ? this.orderBy.estatus = false  // se ordena descendente
          : this.orderBy.estatus
            ? this.orderBy.estatus = false
            : this.orderBy.estatus = true  // se ordena ascendente
        if(this.orderBy.estatus) {
          this.orderByActual = ['estatus_asc', 'numeroHR_desc', 'fecha_desc']
          this.paqueteActualizar('filtro')
        } else {
          this.orderByActual = ['estatus_desc', 'numeroHR_desc', 'fecha_desc']
          this.paqueteActualizar('filtro')
        }
      }
    },
    diaActual() {
      return (new Date()).toISOString().split('T')[0];
    },
    diaHaceTresMeses(){
      var dt = new Date();
      dt.setMonth(dt.getMonth() - 3);
      const diaHaceTresMeses = (dt.toISOString()).split('T')[0]
      return diaHaceTresMeses
    },
    rangoFechaValida(inicial, final) {
      const inicio = new Date(inicial);
      const fin = new Date(final);
      if (inicio < fin) {
        return true;
      } else {
        return false;
      }
    },
    seleccionarTodo() {
      const paquetesDisponibles = this.paquetes.length
      const paquetesSeleccionados = this.paquetesId.length
      paquetesDisponibles === paquetesSeleccionados
        ? this.paquetesId = []
        : this.paquetesId = this.paquetes
    },
    // Validar consolidado
    validarAsignarConsolidado() {
    return this.paquetesEsperaMiami
        ? this.mostrarModalAsignarContenedor = true
        : this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia',
        }
    },
    // Scroll infinito
    scroll() {
      window.onscroll = () => {
        const finalPagina = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
        if (finalPagina && this.mostrarMas) {
          this.paquetesBuscarMas()
        }
      };
    },
    metodoConfirmarPermiso(){
      if(this.metodoAlConfirmar === 'asignarCliente'){
        this.mostrarModalAsignarCliente = true;
        this.objetoSeleccionado = this.objetoAlConfirmarPermiso;
      }
      if(this.metodoAlConfirmar === 'eliminarPaquete'){
        // Validar que al segundo intento se elimine el paquete
        if(this.stepConfirmarClave == 1){
          this.stepConfirmarClave++
          this.mostrarModalConfirmarEliminarPaquete = true
        } else {
          this.stepConfirmarClave = 1
          this.eliminarPaquete()
        }
      }
      this.metodoAlConfirmar = '';
      this.objetoAlConfirmarPermiso = {};
    },
    paqueteReempacar(QRVariables) {
      if(QRVariables && QRVariables.length && QRVariables[0].id){
        this.paquetesId = QRVariables
        if(!this.paquetesTipoIndividual){
          return this.alertaMensaje = {
            contenido:  'Alguno de los paquetes que escaneaste es de tipo reempaque. Por favor verifica tus datos',
            tipo: 'advertencia',
          }
        }
        if(!this.paquetesMismoCliente){
          return this.alertaMensaje = {
            contenido:  'Alguno de los paquetes que escaneaste no tiene cliente o cuenta con clientes diferentes. Por favor verifica tus datos',
            tipo: 'advertencia',
          }
        }
      } 
      if(!this.paquetesEsperaMiami) {
        return this.alertaMensaje = {
          contenido:  'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia',
        }
      }
      this.mostrarModalEscanear = false
      this.mostrarFormularioPaquete = true;
      this.reempaque = true;
      this.modalTitulo = 'Datos del nuevo paquete tipo reempaque'
    },
    paginacionResetear() {
      this.pagina = 1;
      this.mostrarMas = true;
    },
    paquetesBuscarMas() {
      this.loadingPaquetesPaginacion = true;
      const paquetesActualQuery = JSON.parse(JSON.stringify(this.paquetes))
      if(!this.rangoFechaValida(this.buscarPor.fecha.desde, this.buscarPor.fecha.hasta)) {
        this.botonDeshabilitado = false;
        return (this.alertaMensaje = {
          contenido: 'Es necesario que la fecha inicial sea anterior a la fecha final',
          tipo: 'advertencia',
        })
      }
      let filter = {
        reempacado: false,
        estatus_not_in: ['Entregado', 'Devuelto'],
        fecha_gte: {formatted: this.buscarPor.fecha.desde},
        fecha_lte: {formatted: this.buscarPor.fecha.hasta}
      };
      // Mostrar solo paquetes de la agencia si es rol Agencia
      if(this.rol.includes('Agencia') && this.agenciasId.length){
        Object.assign(filter, {
          agenciaDestino_in: this.agenciasId.map(agenciaId => ({id: agenciaId}))
        })
      }
      Object.assign(filter,
        {
          estatus: this.buscarPor.estatus,
          tipo: this.buscarPor.tipo,
          pagado: this.buscarPor.pagado,
        })
      this.buscarPor.entregado
        ? delete filter.estatus_not_in
        : ''
      this.buscarPor.tipo.length
        ? ''
        : delete filter.tipo
      if(this.buscarPor.estatus.length){
         delete filter.estatus_not_in
      }else{
         delete filter.estatus
      }
      if(!this.buscarPor.pagado){
        delete filter.pagado
        if(this.buscarPor.pagado !== ''){
        // Pagado es === false
          Object.assign(filter,
            {
              OR: [ {pagado: false }, {pagado: null }]
            })
        }
      }
      // Validar si se busca por cliente
      if(this.buscarPor.cliente.id && this.buscarPor.cliente.id.length){
        Object.assign(filter, {
          cliente: {
            id: this.buscarPor.cliente.id
          }
        })
      }
      // Validar si se busca por tracking o numeroHR
      if(this.buscarPor.id && this.buscarPor.id.length){
        Object.assign(filter, {
          id: this.buscarPor.id
        })
      }
      this.$apollo.queries.paquetes.fetchMore({
        variables: {
          filter,
          offset: this.pagina * this.variablesUpdateQueryViewPaquetes.first,
          first: this.variablesUpdateQueryViewPaquetes.first,
          orderBy: this.orderByActual && this.orderByActual.length
            ? this.orderByActual
            : this.variablesUpdateQueryViewPaquetes.orderBy
        },
        updateQuery: (previusResult, {fetchMoreResult}) => {
          // console.group("Ejecutado: paqueteBuscarMas")
          // console.table(filter)
          // console.groupEnd("Ejecutado: paqueteBuscarMas")
          // this.logObtenidos += `
          // paqueteBuscarMas. filtros: ${JSON.stringify(filter, 2, null)}`
          const nuevosPaquetes = fetchMoreResult.Paquete;
          const restoPaquetes = fetchMoreResult.Paquete.length >= this.variablesUpdateQueryViewPaquetes.first
          const typename = previusResult?.Paquete?.length
            ? previusResult?.Paquete[0]?.__typename
            : ''
          this.mostrarMas = restoPaquetes;
          if(restoPaquetes){
            this.pagina++;
          }
          this.loadingPaquetesPaginacion = false;
        if(!nuevosPaquetes.length){
          this.pagina = 1
          this.mostrarMas = false
          return {
            __typename: typename,
            Paquete: paquetesActualQuery,
          };
        }
        if(nuevosPaquetes.length){
          const paquetesObtenidos = [...this.paquetes, ...nuevosPaquetes];
          const paquetesRetornadosQuery = JSON.parse(JSON.stringify(paquetesObtenidos))
          this.paquetes = paquetesRetornadosQuery
          return {
            __typename: typename,
            Paquete: paquetesRetornadosQuery,
          };
        }
        }
      });
    },
   paqueteActualizar(filtro) {
      this.loadingPaquetes = true;
      this.paginacionResetear()
      this.botonDeshabilitado = true;
      const variablesQuery = {
        filter: {
          reempacado: false,
          estatus_not_in: ['Entregado', 'Devuelto'],
          fecha_gte: {formatted: this.buscarPor.fecha.desde},
          fecha_lte: {formatted: this.buscarPor.fecha.hasta}
        },
        offset: 0,
        first: this.variablesUpdateQueryViewPaquetes.first,
        orderBy: this.orderByActual && this.orderByActual.length
          ? this.orderByActual
          : this.variablesUpdateQueryViewPaquetes.orderBy
      }
      // Mostrar solo paquetes de la agencia si es rol Agencia
      if(this.rol.includes('Agencia') && this.agenciasId.length){
        Object.assign(variablesQuery.filter, {
          agenciaDestino_in: this.agenciasId.map(agenciaId => ({id: agenciaId}))
        })
      }
      // Validar si es valido el formato de hora
      if(!this.rangoFechaValida(this.buscarPor.fecha.desde, this.buscarPor.fecha.hasta)) {
        this.botonDeshabilitado = false;
        return (this.alertaMensaje = {
          contenido: 'Es necesario que la fecha inicial sea anterior a la fecha final',
          tipo: 'advertencia',
        })
      }
      // Validar si se busca por cliente
      if(this.buscarPor.cliente.id && this.buscarPor.cliente.id.length){
        Object.assign(variablesQuery.filter, {
          cliente: {
            id: this.buscarPor.cliente.id
          }
        })
      }
      // Validar si se busca por tracking o numeroHR
      if(this.buscarPor.id && this.buscarPor.id.length){
        Object.assign(variablesQuery.filter, {
          id: this.buscarPor.id
        })
      }
      if(filtro){
        Object.assign(variablesQuery.filter,
          {
            estatus: this.buscarPor.estatus,
            tipo: this.buscarPor.tipo,
            pagado: this.buscarPor.pagado,
          })
        if(this.buscarPor.entregado){
          delete variablesQuery.filter.estatus_not_in
        }
        if(!this.buscarPor.tipo.length)  delete variablesQuery.filter.tipo
        if(this.buscarPor.estatus.length) {
          // Mostrar entregados y devueltos
          this.buscarPor.estatus === 'Entregado' || this.buscarPor.estatus === 'Devuelto'
            ? this.buscarPor.entregado = true
            : this.buscarPor.entregado = null
          delete variablesQuery.filter.estatus_not_in
        } else {
          delete variablesQuery.filter.estatus
        }
        if(!this.buscarPor.pagado){
          delete variablesQuery.filter.pagado
          if(this.buscarPor.pagado !== ''){
          // Pagado es === false
            Object.assign(variablesQuery.filter,
              {
                OR: [ {pagado: false }, {pagado: null }]
              })
          }
        }
      }
      // console.group("Ejecutado: paqueteActualizar")
      // console.table(variablesQuery.filter)
      // console.groupEnd("Ejecutado: paqueteActualizar")
      // this.logObtenidos += `
          // paqueteActualizar. filtros: ${JSON.stringify(variablesQuery.filter)}`
      this.$apollo
        .watchQuery({
          query: gql`
            ${paquetesGql}
          `,
          variables: variablesQuery,
          update: (data) => data.Paquete,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.Paquete){
              this.paquetes = data.Paquete;
              this.loadingPaquetes = false;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error actualizando los paquetes. Por favor intenta de nuevo',
              tipo: 'error',
            };
            this.botonDeshabilitado = false;
            this.loadingPaquetes = false;
          }
        })
        // Limpiar los paquetes al realizar el watchQuery
        this.paquetesId = [];
    },
    paqueteAsignarCliente() {
      if (!this.cliente.id){
        return this.alertaMensaje = {
            contenido:  'Por favor selecciona un cliente',
            tipo: 'advertencia',
          };
      }
      if (!this.cliente.casillero) {
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
          tipo: 'advertencia',
        };
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarClienteGql}
          `,
          variables: {
            paquetesId: [this.objetoSeleccionado],
            personaId: this.cliente.id,
          },
          update: (store, {data: {paqueteAsignarCliente}}) => {
            const msg = paqueteAsignarCliente.codigo;
            switch (msg) {
              case "Correcto":
                break;
              case "Fallido":
                this.alertaMensaje = {
                    contenido:  'Ha ocurrido un error asignando cliente al paquete.'
                    +' Por favor intenta de nuevo',
                    tipo: 'error',
                  };
                this.botonDeshabilitado = false;
                return;
              default:
                this.alertaMensaje = {
                  contenido:  'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  tipo: 'error',
                };
                this.botonDeshabilitado = false;
                return;
            }

            const data = store.readQuery({
              query: paquetesGql,
              variables: this.variablesUpdateQueryViewPaquetes
            });

            data.Paquete.map((p) => {
              let paquete = p
              if(p.id === this.objetoSeleccionado){
                paquete.cliente = JSON.parse(JSON.stringify(this.cliente))
                // Propiedades que no retorna la query clienteBuscar
                paquete.cliente.emails = []
                paquete.cliente.telefonos = []
                // Actualizar número HR
                paquete.numeroHR = paquete.cliente.casillero+obtenerMarcaTemporal()
              }
              return (paquete)
            })

            store.writeQuery({
              query: paquetesGql,
              variables: this.variablesUpdateQueryViewPaquetes,
              data
            });

            this.cliente = {};
            this.mostrarModalAsignarCliente= false;
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: 'Se ha asignado un cliente al paquete correctamente',
              tipo: 'correcto',
            };
          },
        })
        .catch(()=>{
          this.botonDeshabilitado = false
          return this.alertaMensaje = {
            contenido:  'Ha ocurrido un error asignando cliente al paquete.'
            +' Por favor intenta de nuevo',
            tipo: 'error',
          };
        })
    },
  },
  apollo: {
    paquetes() {
      return {
        query() {
          return gql`${paquetesGql}`;
        },
        variables:() => {
          return this.variablesUpdateQueryViewPaquetes
        },
        debounce: 1000,
        update: (data) => data.Paquete,
      };
    },
  },
};

</script>

<style lang="scss" scoped>
.encabezado-pagina {
  @media screen and (min-width: 576px) {
    flex-direction: column;
    h2 {text-align: center;}
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    h2 {text-align: left;}
  }
}
.mb-page {
  margin-bottom: 11rem !important;

  @media screen and (min-width: 768px) {
    margin-bottom: 4rem !important;
  }
}
.botones-opcionales {
  align-items: flex-end;
  bottom: 73px;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  padding-right: 1rem;
  position: fixed;
  right: 0px;
  transition: height 0.5s ease;
  width: auto;
  z-index: 2;

  @media screen and (min-width: 576px) {
    bottom: 68px;
  }
  @media screen and (min-width: 768px) {
    bottom: 1rem;
    flex-direction: row;
    justify-content:center;
    padding-right: 0;
    width: 100%;
  }
  @media screen and (min-width: 992px) {
    width: calc(100% - 230px);
  }

  &.mostrar {
    height: 175px;

    &.opcionRoleAdmin {
      height: 232px;
    }
  }
  &.mostrar,
  &.mostrar.opcionRoleAdmin {
    @media screen and (min-width: 768px) {height: 60px;}
  }

  .bg-white.d-inline-block {
    margin: 6px;
  }
}
</style>
